.staff-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.staff-list__title {
  text-align: center;
}
