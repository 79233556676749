.staff-item {
  margin: 1rem;
  width: calc(40% - 2rem);
  min-width: 10rem;
  max-width: 45rem;
}
.staff-item :hover {
  cursor: pointer;
}

.staff-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: black;
  background: #92a9bd;
}

.staff-item a:hover,
.staff-item a:active {
  background: #92a9bd;
}

.staff-item__content {
  text-align: center;
  padding: 0;
}
.staff-item__modal-image-div {
  width: 20rem;
  height: 20rem;
  margin-right: auto;
  margin-left: auto;
}
.staff-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: auto;
  margin-left: auto;
}

.staff-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: black;
}

.staff-item:hover h2,
.staff-item:active h2,
.staff-item:hover h3,
.staff-item:active h3 {
  color: #ffefef;
}

.staff-item__info h3 {
  margin: 0;
}
