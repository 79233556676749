.WelcomeSlideShow__each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
  color: #8ca1a5;
  font-family: monospace;
}

.WelcomeSlideShow__each-slide span {
  padding: 2%;
  font-size: 20px;
  background: transparent;
  text-align: center;
  align-self: flex-end;
}
.WelcomeSlideShow__image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
